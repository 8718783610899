import React from "react";
import { Link } from "react-router-dom";
import ChooseWrapper from "./ChooseStyles";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import Navbar from "../Navbar/Navbar";

const mapStateToProps = (state, ownProps) => {
  return state && state.data.data
    ? {
        recibidas: state.data.data.recibidas,
        emitidas: state.data.data.emitidas,
        date: state.data.data.date,
        hour: state.data.data.hour,
        receivedInvoices: state.data.data.receivedInvoices,
        issuedInvoices: state.data.data.issuedInvoices,
        myReceivedInvoices: state.data.data.myReceivedInvoices,
        myIssuedInvoices: state.data.data.myIssuedInvoices
      }
    : "";
};

const columns = [
  {
    id: "FechaComprobacion",
    Header: "Fecha Comprobación",
    accessor: d => d.date
  },
  {
    id: "HoraComprobacion",
    Header: "Hora Comprobación",
    accessor: d => d.hour
  },
  {
    id: "Tipo",
    Header: "Tipo",
    accessor: d => d.type
  },
  {
    id: "NFacturasLibro",
    Header: "F. Libro de registro",
    accessor: d => d.initialQuantity
  },
  {
    id: "NFacturasJustificante",
    Header: "F. Justificante SII",
    accessor: d => d.finalQuantity
  },
  {
    id: "NFacturas",
    Header: "F. Comprobadas",
    accessor: d => d.quantity
  },
  {
    id: "vermas",
    Header: "Ver en detalle",
    accessor: d => <Link to={`${d.type}`.toLowerCase()}>Ver</Link>
  }
];
const _Choose = ({
  recibidas,
  emitidas,
  date,
  hour,
  receivedInvoices,
  issuedInvoices,
  myReceivedInvoices,
  myIssuedInvoices
}) => {
  let data;
  if (!recibidas) {
    data = [
      {
        date,
        hour,
        type: "Emitidas",
        initialQuantity: myIssuedInvoices,
        finalQuantity: issuedInvoices,
        quantity: emitidas.length
      }
    ];
  } else if (!emitidas) {
    data = [
      {
        date,
        hour,
        type: "Recibidas",
        initialQuantity: myReceivedInvoices,
        finalQuantity: receivedInvoices,
        quantity: recibidas.length
      }
    ];
  } else if (recibidas && emitidas) {
    data = [
      {
        date,
        hour,
        type: "Recibidas",
        initialQuantity: myReceivedInvoices,
        finalQuantity: receivedInvoices,
        quantity: recibidas.length
      },
      {
        date,
        hour,
        type: "Emitidas",
        initialQuantity: myIssuedInvoices,
        finalQuantity: issuedInvoices,
        quantity: emitidas.length
      }
    ];
  }

  return (
    <React.Fragment>
      <Navbar link="Historico" />
      <ChooseWrapper>
        {data && (
          <ReactTable
            data={data}
            columns={columns}
            defaultPageSize={data.length}
            showPageSizeOptions={false}
            showPagination={false}
          />
        )}
      </ChooseWrapper>
    </React.Fragment>
  );
};

export const Choose = connect(mapStateToProps)(_Choose);
