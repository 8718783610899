import React from "react";
import { NavbarStyles, NavbarWrapper } from "./NavbarStyles";
import { Link } from "react-router-dom";
import logo from "../../images/icon.svg";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  return state && state.data.data
    ? {
        data: state.data.data
      }
    : "";
};

class _Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ""
    };
  }

  componentDidMount() {
    const { link } = this.props;
    if (link === "Salir") {
      this.setState({ ...this.state, url: "/" });
    } else if (this.props.link === "Historico") {
      this.setState({ ...this.state, url: "/historico" });
    }
  }

  // let url;

  render() {
    const { url } = this.state;
    const { link, data, backgroundColor } = this.props;
    return (
      <NavbarStyles backgroundColor={backgroundColor}>
        <NavbarWrapper>
          <Link to="/">
            <img src={logo} alt="tailor-logo" />
          </Link>
          <ul>
            {data && <Link to={'/selecciona'}>Última revisión</Link>}
            <Link to={url}>{link}</Link>
          </ul>
        </NavbarWrapper>
      </NavbarStyles>
    );
  }
}

const Navbar = connect(mapStateToProps)(_Navbar)
export default Navbar