import styled from 'styled-components';

export const UploaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;

  .form-container {
    width: 95%;
    margin:0 auto;
  }

  .form-content {
    margin: 0 auto;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    label {
      margin: 0 auto;
    }
  }

  .upload-buttom {
    margin-top: 2%;
  }
`