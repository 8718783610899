import React, { Component } from "react";
import { FileService } from "../../services/fileService";
import XLSX from "xlsx";
import Spinner from "../Spinner";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { changeData } from "../../actions";
import Input from "@material-ui/core/Input";
import Navbar from "../Navbar/Navbar";
import { UploaderWrapper } from "./UploaderStyles";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Error from "../Error/Error";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    color: "white",
    backgroundColor: "#0f1c51",
    marginTop: "2%"
  },
  input: {
    display: "none"
  }
});
class _Uploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recibidas: {
        fileName: "",
        data: [],
        columns: []
      },
      emitidas: {
        fileName: "",
        data: [],
        columns: []
      },
      misFacturas: {
        misRecibidas: {
          fileName: "",
          data: [],
          columns: []
        },
        misEmitidas: {
          fileName: "",
          data: [],
          columns: []
        }
      },
      redirect: false,
      uploading: false,
      error: false,
      message: "",
      navColor: "transparent"
    };
  }

  getResult(e, sheet = 0) {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: "array" });
    const wsname = workbook.SheetNames[sheet];
    const ws = sheet === 0 ? workbook.Sheets[wsname] : workbook.Sheets[sheet];
    if (!ws) {
      this.setState(
        {
          ...this.state,
          error: true,
          message:
            "No ha sido posible extraer la información del archivo subido"
        },
        () => (document.querySelector("#misFacturas").value = "")
      );
      return [null, null];
    } else {
      /* Convert array of arrays */
      let json = XLSX.utils.sheet_to_json(ws, { header: 1 });
      let firstArray = json.shift();
      firstArray = Object.values(firstArray).map(el =>
        el
          .replace(/[%]/g, "Porcentaje")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .replace(/\s/g, "")
      );
      json = json.map((array, i) => {
        return array.reduce((acc, element, i) => {
          acc[firstArray[i]] = element;
          return acc;
        }, {});
      });

      return [json, firstArray];
    }
  }

  getFileName = fileName => {
    return fileName
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s/g, "")
      .replace(/xlsx/gi, "");
  };

  handleChange = e => {
    this.deleteError()
    const files = e.target.files,
      f = files[0];
    if (f) {
      const { name } = e.target;
      let result, misRecibidasResult, misEmitidasResult;
      const reader = new FileReader();
      reader.onload = e => {
        if (name !== "misFacturas") {
          let [json, firstArray] = this.getResult(e);
          if (json && firstArray) {
            result = {
              fileName: this.getFileName(f.name),
              data: [...json],
              columns: [...firstArray]
            };
          }
        } else {
          const [json, firstArray] = this.getResult(e, "Recibidas");
          const [json2, firstArray2] = this.getResult(e, "Emitidas");
          if ((json && firstArray) || (json2 && firstArray2)) {
            misRecibidasResult = {
              fileName: this.getFileName(`${f.name}Recibidas`),
              data: [...json],
              columns: [...firstArray]
            };

            misEmitidasResult = {
              fileName: this.getFileName(`${f.name}Emitidas`),
              data: [...json2],
              columns: [...firstArray2]
            };

            result = { misRecibidasResult, misEmitidasResult };
          }
        }
        switch (name) {
          case "recibidas": {
            if(result.columns.includes("NIFEMISOR") || result.columns.includes("NIFEmisor")) {
              this.setState({ ...this.state, recibidas: result });
            } else {
              this.handleError("No ha sido posible extraer la información del archivo subido", "#recibidas")
            }
            break;
          }
          case "emitidas" : {
            if(result.columns.includes("NIFDestinatario") || result.columns.includes("NIFDESTINATARIO") ) {
              this.setState({ ...this.state, emitidas: result });
            } else {
              this.handleError("No ha sido posible extraer la información del archivo subido", "#emitidas")
            }
            
            break;
          }
          case "misFacturas": {
            this.setState({ ...this.state, misFacturas: result });
            break;
          }
          default:
            break;
        }
      };
      reader.readAsArrayBuffer(f);
    }
  };

  handleError = (message, id) => {
    this.setState(
      {
        ...this.state,
        error: true,
        message
      },
      () => (document.querySelector(`${id}`).value = "")
    );
  }

  componentDidMount() {
    this.changeNavScroll();
  }

  deleteError =  () => {
    this.setState(
      {
        ...this.state,
        error: false,
        message:""
      }
    );
  }
  isEmpty = array => array.length === 0;

  submitForm = e => {
    e.preventDefault();
    const { recibidas, emitidas, misFacturas } = this.state;
    if (this.isEmpty(recibidas.data) && this.isEmpty(emitidas.data)) {
      this.setState({
        ...this.state,
        error: true,
        message: "Archivos insuficientes para realizar la consulta"
      });
    } else if (this.isEmpty(recibidas.data)) {
      this.setState({ ...this.state, uploading: true, error: false });
      FileService.sendFiles(null, emitidas, misFacturas).then(res => {
        const { dispatch } = this.props;
        const {
          data,
          date,
          hour,
          receivedInvoices,
          issuedInvoices,
          myReceivedInvoices,
          myIssuedInvoices,
          issuedAmounts,
          recivedAmounts
        } = res;
        const newData = { ...data, recibidas: null };
        dispatch(
          changeData(
            newData,
            date,
            hour,
            receivedInvoices,
            issuedInvoices,
            myReceivedInvoices,
            myIssuedInvoices,
            issuedAmounts,
            recivedAmounts
          )
        );
        this.setState({ ...this.state, uploading: false, redirect: true });
      });
    } else if (this.isEmpty(emitidas.data)) {
      this.setState({ ...this.state, uploading: true, error: false });
      FileService.sendFiles(recibidas, null, misFacturas).then(res => {
        const { dispatch } = this.props;
        const {
          data,
          date,
          hour,
          receivedInvoices,
          issuedInvoices,
          myReceivedInvoices,
          myIssuedInvoices,
          issuedAmounts,
          recivedAmounts
        } = res;
        const newData = { ...data, emitidas: null };
        dispatch(
          changeData(
            newData,
            date,
            hour,
            receivedInvoices,
            issuedInvoices,
            myReceivedInvoices,
            myIssuedInvoices,
            issuedAmounts,
            recivedAmounts
          )
        );
        this.setState({ ...this.state, uploading: false, redirect: true });
      });
    } else {
      this.setState({ ...this.state, uploading: true, error: false });
      FileService.sendFiles(recibidas, emitidas, misFacturas)
        .then(res => {
          const { dispatch } = this.props;
          const {
            data,
            date,
            hour,
            receivedInvoices,
            issuedInvoices,
            myReceivedInvoices,
            myIssuedInvoices,
            issuedAmounts,
            recivedAmounts
          } = res;
          dispatch(
            changeData(
              data,
              date,
              hour,
              receivedInvoices,
              issuedInvoices,
              myReceivedInvoices,
              myIssuedInvoices,
              issuedAmounts,
              recivedAmounts
            )
          );
          this.setState({ ...this.state, uploading: false, redirect: true });
        })
        .catch(err =>
          this.setState({
            ...this.state,
            error: true,
            message: `${err.message}`
          })
        );
    }
  };

  changeNavScroll = () => {
    window.addEventListener("scroll", () => {
      window.scrollY > 5
        ? this.setState({ ...this.state, navColor: "white" })
        : this.setState({ ...this.state, navColor: "transparent" });
    });
  };
  render() {
    if (this.state && this.state.redirect) {
      return <Redirect to="/selecciona" />;
    }
    const { uploading, error, message, navColor } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Navbar link="Historico" backgroundColor={navColor} />
        <UploaderWrapper>
          <h2>Herramienta SII</h2>
          {!uploading ? (
            <form onSubmit={e => this.submitForm(e)} className="form-container">
              <div className="form-content">
                <label htmlFor="recibidas">
                  Justificante Facturas recibidas SII:{" "}
                </label>

                <Input
                  label="Justificante Facturas recibidas SII: "
                  type="file"
                  name="recibidas"
                  id="recibidas"
                  onChange={e => this.handleChange(e)}
                />
              </div>
              <div className="form-content">
                <label htmlFor="emitidas">
                  Justificante Facturas emitidas SII:{" "}
                </label>
                <Input
                  type="file"
                  name="emitidas"
                  id="emitidas"
                  onChange={e => this.handleChange(e)}
                />
              </div>
              <div className="form-content">
                <label htmlFor="misFacturas">Libro de registro: *</label>
                <Input
                  required
                  type="file"
                  name="misFacturas"
                  id="misFacturas"
                  onChange={e => this.handleChange(e)}
                />
              </div>
              <Button
                variant="contained"
                type="submit"
                className={classes.button}
              >
                Subir archivos
              </Button>
            </form>
          ) : (
            <Spinner uploading={uploading} />
          )}
          {error && <Error message={message} />}
        </UploaderWrapper>
      </React.Fragment>
    );
  }
}

export const Uploader = withStyles(styles)(connect()(withRouter(_Uploader)));
