export const changeData = (
  data,
  date,
  hour,
  receivedInvoices,
  issuedInvoices,
  myReceivedInvoices,
  myIssuedInvoices,
  issuedAmounts,
  recivedAmounts
) => ({
  type: "CHANGE_DATA",
  data,
  date,
  hour,
  receivedInvoices,
  issuedInvoices,
  myReceivedInvoices,
  myIssuedInvoices,
  issuedAmounts,
  recivedAmounts
});
