import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  withCredentials: true
});

export class FileService {
  static errorHandler(e) {
    console.error("FILE API ERROR");
    console.error(e);
    throw e;
  }

  static sendFiles(recibidas, emitidas, misFacturas) {
    return instance
      .post("/files/save", { recibidas, emitidas, misFacturas })
      .then(res => res.data)
      .catch(FileService.errorHandler);
  }

  static getRecibidas() {
    return instance
      .get("/files/recibidas")
      .then(res => res.data)
      .catch(FileService.errorHandler);
  }

  static getEmitidas() {
    return instance
      .get("/files/emitidas")
      .then(res => res.data)
      .catch(FileService.errorHandler);
  }

  static getHistorico(type, date = null) {
    return instance
      .post(`/files/historico${type}`, {date})
      .then(res => res.data)
      .catch(FileService.errorHandler);
  }
}
