import React, { Component } from 'react';
import './App.css';
import { Uploader } from './components/Uploader/Uploader';
import {Switch, Route} from 'react-router-dom';
import {Choose} from './components/Choose/Choose';
import {Received} from './components/Received/Received';
import {Issued} from './components/Issued/Issued';
import Historical from './components/Historical/Historical'

class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={Uploader} />
          <Route exact path="/selecciona" component={Choose} />
          <Route exact path="/recibidas" component={Received} />
          <Route exact path="/emitidas" component={Issued} />
          <Route exact path="/historico" component={Historical} />
        </Switch>
      </div>
    );
  }
}

export default App;
