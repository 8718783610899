import styled from "styled-components";

const ChooseWrapper = styled.div`
  margin-top: 150px;
  .ReactTable {
    width: 95%;
    margin: 0 auto;
    height: 60vh;
    input[type="checkbox"] {
      border: 1px solid #0f1c51;
      background-color: #0b0095;
    }
    background: white;
  }

  .rt-tbody .rt-td {
    border-right: 1px solid rgba(0, 0, 0, 0.02);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type="checkbox"] {
    border: 1px solid #0f1c51;
    background-color: #0b0095;
  }

  a {
    color: #0f1c51;
  }

  a:visited {
    color: #0f1c51;
  }

  .ReactTable .-pagination select {
    border: 1px solid #0f1c51;
    background: white;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 4px;
    outline: none;
  }

  .good {
    color: green;
  }

  .wrong {
    color: red;
  }

  .uncompleted {
    color: orange;
  }
`;

export default ChooseWrapper;