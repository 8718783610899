import React from 'react'
import ErrorWrapper from './ErrorStyles';

const Error = ({message}) => {
  return (
    <ErrorWrapper>
      { message }
    </ErrorWrapper>
  )
}

export default Error
