import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ReceivedWrapper from "./ReceivedStyles";
import XLSX from "xlsx";
import { connect } from "react-redux";
import Navbar from "../Navbar/Navbar";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    backgroundColor: "#008000",
    color: "white",
    height: 'auto'
  },
  input: {
    display: "none"
  },
  card: {
    color: "#0f1c51",
    paddingBottom: 0
  },
  title: {
    fontSize: 18,
    color: "#0f1c51"
  },
  amount: {
    fontSize: 16,
    color: "#0f1c51",
  }
});

const mapStateToProps = (state, ownProps) => {
  return state && state.data.data
    ? {
        recibidas: state.data.data.recibidas,
        date: state.data.data.date,
        hour: state.data.data.hour,
        receivedAmounts: state.data.data.receivedAmounts
      }
    : "";
};
class _Received extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      wb: null,
      navColor: "transparent"
    };
  }
  componentDidMount() {
    this.changeNavScroll();
    const { recibidas } = this.props;
    if (recibidas) {
      this.setState({ ...this.state, data: recibidas }, () => {
        this.generateFile();
      });
    }
  }

  getQuantity = el => {
    return (
      +el.BaseImponible1 +
      +el.CuotaIva1 +
      +el.BaseImponible2 +
      +el.CuotaIva2 +
      +el.BaseImponible3 +
      +el.CuotaIva3 +
      +el.BaseImponible4 +
      +el.CuotaIva4 -
      +el.CuotaRetenc -
      +el.CuotaRec2 -
      +el.CuotaRec3 -
      +el.CuotaRec4 
    );
  };

  generateFile = () => {
    const { data } = this.state;
    const { receivedAmounts } = this.props;
    const json = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    const receivedAmountsJson = XLSX.utils.json_to_sheet([receivedAmounts]);
    XLSX.utils.book_append_sheet(wb, json, "comparacionrecibidas");
    XLSX.utils.book_append_sheet(
      wb,
      receivedAmountsJson,
      "sumatoriosrecibidas"
    );
    this.setState({ ...this.state, wb });
  };

  downloadFile = () => {
    const { wb } = this.state;
    XLSX.writeFile(wb, "output.xlsx");
  };

  columns = [
    {
      id: "NFactura",
      Header: "Número de Factura",
      accessor: d => d.NFactura
    },
    {
      id: "Proveedor",
      Header: "Proveedor",
      accessor: d => d.Proveedor
    },
    {
      id: "NIF",
      Header: "NIF",
      accessor: d => d.NIF
    },
    {
      id: "BaseImponible1",
      Header: "Base Imponible 1",
      accessor: d => d.BaseImponible1,
      filterable: false
    },
    {
      id: "CuotaIva1",
      Header: "Cuota Iva 1",
      accessor: d => d.CuotaIva1,
      filterable: false
    },
    {
      id: "BaseImponible2",
      Header: "Base Imponible 2",
      accessor: d => d.BaseImponible2,
      filterable: false
    },
    {
      id: "CuotaIva2",
      Header: "Cuota Iva 2",
      accessor: d => d.CuotaIva2,
      filterable: false
    },
    {
      id: "BaseImponible3",
      Header: "Base Imponible 3",
      accessor: d => d.BaseImponible3,
      filterable: false
    },
    {
      id: "CuotaIva3",
      Header: "Cuota Iva 3",
      accessor: d => d.CuotaIva3,
      filterable: false
    },
    {
      id: "BaseImponible4",
      Header: "Base Imponible 4",
      accessor: d => d.BaseImponible4,
      filterable: false
    },
    {
      id: "CuotaIva4",
      Header: "Cuota Iva 4",
      accessor: d => d.CuotaIva4,
      filterable: false
    },
    {
      id: "ImporteFactura",
      Header: "Importe Factura",
      accessor: d => {
        const totalQuantity = Math.round(this.getQuantity(d) * 100) / 100;
        return this.roundNumber(+d.ImporteFactura) === totalQuantity ? (
          <span className="good">{this.roundNumber(+d.ImporteFactura)}</span>
        ) : (
          <span className="wrong">{this.roundNumber(d.ImporteFactura)}</span>
        );
      },
      filterable: false
    },
    {
      Header: "Estado",
      accessor: "Estado",
      Cell: props => {
        const { value } = props;
        return value === "Incompleta" ? (
          <span className="uncompleted">{value}</span>
        ) : value === "Correcta" ? (
          <span className="good">{value}</span>
        ) : (
          <span className="wrong">{value}</span>
        );
      }
    }
  ];

  changeNavScroll = () => {
    window.addEventListener("scroll", () => {
      window.scrollY > 5
        ? this.setState({ ...this.state, navColor: "white" })
        : this.setState({ ...this.state, navColor: "transparent" });
    });
  };

  roundNumber = (num) => Math.round(num * 100) / 100

  render() {
    const { data, navColor } = this.state;
    const { classes, receivedAmounts } = this.props;
    const { Interiores, PaisTercero, Intracomunitarias } = receivedAmounts;
    return (
      <React.Fragment>
        <Navbar link="Historico" backgroundColor={navColor} />
        <ReceivedWrapper>
          {data && (
            <React.Fragment>
              <div className="export-button">
                <Card className={classes.card}>
                  <CardContent className={"content"}>
                    <Typography component="p">
                      {
                        <React.Fragment>
                          <strong className={classes.title}>Sumatorios  </strong>
                          <span className={classes.amount}>Interiores: {this.roundNumber(+Interiores)}€ </span> -
                          <span className={classes.amount}> Intracomunitarias: {this.roundNumber(+Intracomunitarias)}€ </span> - 
                          <span className={classes.amount}> País tercero: {this.roundNumber(+PaisTercero)}€ </span>
                        </React.Fragment>
                      }
                    </Typography>
                  </CardContent>
                </Card>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={this.downloadFile}
                >
                  Exportar a excel
                </Button>
              </div>
              <div>
                <ReactTable
                  data={data}
                  columns={this.columns}
                  showPagination={true}
                  filterable={true}
                  defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                      ? String(row[id].toLowerCase()).includes(
                          filter.value.toLowerCase()
                        )
                      : true;
                  }}
                  previousText="Anterior"
                  nextText="Siguiente"
                  loadingText="Cargando información..."
                  noDataText="Sin datos"
                  pageText="Página"
                  ofText="de"
                  rowsText="filas"
                />
              </div>
            </React.Fragment>
          )}
        </ReceivedWrapper>
      </React.Fragment>
    );
  }
}

export const Received = withStyles(styles)(connect(mapStateToProps)(_Received));
