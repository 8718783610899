import React from "react";
import { RingLoader } from "react-spinners";

const Spinner = ({ loading }) => {
  return (
    <RingLoader
      css={{"margin": "0 auto"}}
      sizeUnit={"px"}
      size={50}
      color={"#0f1c51"}
      loading={loading}
    />
  );
};

export default Spinner;
