import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

class HistoricalTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { type, data } = this.props;
    if (type !== prevProps.type || data !== prevProps.data) {
      this.setState({ ...this.state, data: data, type: type });
    }
  }

  componentDidMount() {
    this.setState({ ...this.state, data: this.props.data });
  }

  formatTime = time => {
    const date = new Date(+time);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
  };

  getQuantity = el => {
    const cuotaRec1 = this.props.type === "recibidas" ? el.CuotaRetenc : el.CuotaRetencion
    return (
      +el.BaseImponible1 +
      +el.CuotaIva1 +
      +el.BaseImponible2 +
      +el.CuotaIva2 +
      +el.BaseImponible3 +
      +el.CuotaIva3 +
      +el.BaseImponible4 +
      +el.CuotaIva4 -
      +cuotaRec1 -
      +el.CuotaRec2 -
      +el.CuotaRec3 -
      +el.CuotaRec4 
    );
  };

  getColumns = () => {
    const column =
      this.props.type === "recibidas"
        ? {
            id: "Proveedor",
            Header: "Proveedor",
            accessor: d => d.Proveedor
          }
        : {
            id: "Cliente",
            Header: "Cliente",
            accessor: d => d.Cliente
          };

    return [
      {
        id: "FechaConsulta",
        Header: "Fecha de consulta",
        accessor: d => d.FechaComprobacion,
        filterable: false
      },
      {
        id: "HoraConsulta",
        Header: "Hora de la consulta",
        accessor: d => this.formatTime(d.HoraComprobacion),
        filterable: false
      },
      {
        id: "NFactura",
        Header: "Número de Factura",
        accessor: d => d.NFactura
      },
      column,
      {
        id: "NIF",
        Header: "NIF",
        accessor: d => d.NIF
      },
      {
        id: "BaseImponible1",
        Header: "Base Imponible 1",
        accessor: d => d.BaseImponible1,
        filterable: false
      },
      {
        id: "CuotaIva1",
        Header: "Cuota Iva 1",
        accessor: d => d.CuotaIva1,
        filterable: false
      },
      {
        id: "BaseImponible2",
        Header: "Base Imponible 2",
        accessor: d => d.BaseImponible2,
        filterable: false
      },
      {
        id: "CuotaIva2",
        Header: "Cuota Iva 2",
        accessor: d => d.CuotaIva2,
        filterable: false
      },
      {
        id: "BaseImponible3",
        Header: "Base Imponible 3",
        accessor: d => d.BaseImponible3,
        filterable: false
      },
      {
        id: "CuotaIva3",
        Header: "Cuota Iva 3",
        accessor: d => d.CuotaIva3,
        filterable: false
      },
      {
        id: "ImporteFactura",
        Header: "Importe Factura",
        accessor: d => {
          const totalQuantity = Math.round(this.getQuantity(d) * 100) / 100;
          return this.roundNumber(+d.ImporteFactura) === totalQuantity ? (
            <span className="good">{this.roundNumber(+d.ImporteFactura)}</span>
          ) : (
            <span className="wrong">{this.roundNumber(d.ImporteFactura)}</span>
          );
        },
        filterable: false
      },
      {
        Header: "Estado",
        accessor: "Estado",
        Cell: props => {
          const { value } = props;
          return value === "Incompleta" ? (
            <span className="uncompleted">{value}</span>
          ) : value === "Correcta" ? (
            <span className="good">{value}</span>
          ) : (
            <span className="wrong">{value}</span>
          );
        }
      }
    ];
  };

  roundNumber = (num) => Math.round(num * 100) / 100

  render() {
    const { data } = this.state;
    return (
      <React.Fragment>
        {data && (
          <ReactTable
            data={data}
            columns={this.getColumns()}
            showPagination={true}
            filterable={true}
            previousText="Anterior"
            nextText="Siguiente"
            loadingText="Cargando información..."
            noDataText="Sin datos"
            pageText="Página"
            ofText="de"
            rowsText="filas"
            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined
                ? String(row[id]).includes(filter.value)
                : true;
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HistoricalTable;
