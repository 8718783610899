const initialStore = {
  data: null,
}

const data = (state = initialStore, action) => {
  switch (action.type) {
    case "CHANGE_DATA":
      const { data } = action;
      return {
        ...state,
        data
      };
    default:
      return state;
  }
};

export default data;
