import React, { Component } from "react";
import HistoricalWrapper from "./HistoricalStyles";
import { FileService } from "../../services/fileService";
import HistoricalTable from "./HistoricalTable/HistoricalTable";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import Navbar from "../Navbar/Navbar";
import TabContainer from "./TabContainer";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import XLSX from "xlsx";
const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  root: {
    flexGrow: 1,
    backgroundColor: '#f7f7f7'
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: "#008000",
    color: "white"
  },
  input: {
    display: "none"
  }
});

class Historical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      type: "recibidas",
      date: moment().format("YYYY-MM-DD"),
      value: 0,
      lastDate: moment().format("YYYY-MM-DD"),
      wb: null,
      navColor: "transparent"
    };
  }

  componentDidMount() {
    this.changeNavScroll();
    FileService.getHistorico("recibidas").then(recibidas => {
      FileService.getHistorico("emitidas").then(emitidas => {
        if (
          moment(recibidas.date, "DD-MM-YYYY").format("YYYY-MM-DD") <
          moment(emitidas.date, "DD-MM-YYYY").format("YYYY-MM-DD")
        ) {
          this.setState({
            ...this.state,
            data: emitidas.data,
            date: moment(emitidas.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            lastDate: moment(emitidas.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            type: 'emitidas',
            value:1
          });
        } else {
          this.setState({
            ...this.state,
            data: recibidas.data,
            date: moment(recibidas.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            lastDate: moment(recibidas.date, "DD-MM-YYYY").format("YYYY-MM-DD")
          });
        }
      });
    });
  }

  formatTime = time => {
    const date = new Date(+time);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
  };

  generateFile = () => {
    const { data, type } = this.state;
    const exportableData = data.map(invoice =>({...invoice, HoraComprobacion: this.formatTime(invoice.HoraComprobacion)}))
    const json = XLSX.utils.json_to_sheet(exportableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, json, type);
    this.setState({ ...this.state, wb }, () => {
      this.downloadFile();
    });
  };

  downloadFile = () => {
    const { wb } = this.state;
    XLSX.writeFile(wb, "output.xlsx");
  };

  handleChange = e => {
    const { value } = e.target;

    this.setState({ ...this.state, date: value }, () => {
      const { type, date } = this.state;
      FileService.getHistorico(type, moment(date).format("DD-MM-YY")).then(
        result => {
          this.setState({ ...this.state, data: result.data, type });
        }
      );
    });
  };

  changeData = e => {
    const name = e.target.innerHTML;
    const { type, date } = this.state;
    if (type === "recibidas" && name !== "Recibidas") {
      FileService.getHistorico(
        "emitidas",
        moment(date).format("DD-MM-YY")
      ).then(result => {
        this.setState({
          ...this.state,
          data: result.data,
          type: "emitidas"
        });
      });
    } else if (type === "emitidas" && name !== "Emitidas") {
      FileService.getHistorico(
        "recibidas",
        moment(date).format("DD-MM-YY")
      ).then(result => {
        this.setState({
          ...this.state,
          data: result.data,
          type: "recibidas"
        });
      });
    }
  };

  handleTabChange = (event, value) => {
    this.setState({ ...this.state, value });
  };

  changeNavScroll = () => {
    window.addEventListener("scroll", () => {
      window.scrollY > 5
        ? this.setState({ ...this.state, navColor: "white" })
        : this.setState({ ...this.state, navColor: "transparent" });
    });
  };

  render() {
    const { data, type, date, value, lastDate, navColor } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Navbar link="Salir" backgroundColor={navColor}/>
        <HistoricalWrapper>
          <div className="dates-input">
            {date && (
              <div className="date-inputs-container">
                <div>
                  <TextField
                    id="date2"
                    label="Fecha de la última consulta"
                    type="date"
                    value={lastDate}
                    name="date2"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    formatDate={dateToFormat =>
                      moment(dateToFormat).format("DD-MM-YY")
                    }
                    disabled
                  />
                  <TextField
                    id="date"
                    label="Nueva consulta"
                    type="date"
                    value={date}
                    name="date"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={e => this.handleChange(e)}
                    formatDate={dateToFormat =>
                      moment(dateToFormat).format("DD-MM-YY")
                    }
                  />
                </div>

                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={this.generateFile}
                >
                  Exportar a excel
                </Button>
              </div>
            )}
          </div>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={this.handleTabChange}
                className={"tab-background"}
              >
                <Tab onClick={e => this.changeData(e)} label="Recibidas" />
                <Tab onClick={e => this.changeData(e)} label="Emitidas" />
              </Tabs>
            </AppBar>
            {value === 0 && (
              <TabContainer>
                {data && <HistoricalTable data={data} type={type} />}
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                {data && <HistoricalTable data={data} type={type} />}
              </TabContainer>
            )}
          </div>
        </HistoricalWrapper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Historical);
